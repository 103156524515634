import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const fetchSeoData = async (domain) => {
  try {
    const response = await fetch(
      "https://api-landing.fusionkitchen.co.uk/getSeoData",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ domain }),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching SEO data:", error);
    return {}; // Return an empty object on error
  }
};

const Seo = () => {
  useEffect(() => {
    // const domain = window.location.hostname;
    const domain = window.location.hostname.replace(/^www\./, "");
    fetchSeoData(domain).then((seoData) => {
      if (seoData.message.tracking_id) {
        const metaTag = document.createElement("meta");
        metaTag.name = "google-site-verification";
        metaTag.content = seoData.message.tracking_id;
        document.head.appendChild(metaTag);
      }
    });
  }, []);

  return (
    <Helmet>
      {/* Other static or dynamic meta tags can be placed here */}
    </Helmet>
  );
};

export default Seo;
