const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      const setMetadata = (metric) => {
        onPerfEntry(metric.name, metric.delta);
      }
      getCLS(setMetadata);
      getFID(setMetadata);
      getFCP(setMetadata);
      getLCP(setMetadata);
      getTTFB(setMetadata);
    });
  }
};

export default reportWebVitals;
