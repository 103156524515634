import React from "react";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { HelmetProvider } from "react-helmet-async";
import store from "./store";
import { createRoot } from "react-dom/client";
import Seo from "./seo";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
// const stripePromise = loadStripe("pk_live_DIodJvzwckwG0omwLcjh3E2k00A880PBDB");
// const stripePromise = loadStripe("pk_test_9CnYE16SY0ju0M4GcnOBHzku00gC8VQDPF");

const fetchBusinessData = async (domain) => {
  try {
    const response = await fetch(
      "https://api-landing.fusionkitchen.co.uk/businessType",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ domain }),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching business data:", error);
    return {}; // Return an empty object on error
  }
};

const renderApp = async () => {
  const domain = window.location.hostname;

  try {
    const apiResponse = await fetchBusinessData(domain);

    createRoot(document.getElementById("root")).render(
      <Elements stripe={stripePromise}>
        <React.StrictMode>
          <Provider store={store}>
            <HelmetProvider>
              <Seo />
              <App apiResponse={apiResponse?.data?.businessType} />
            </HelmetProvider>
          </Provider>
        </React.StrictMode>
      </Elements>
    );
  } catch (error) {
    console.error("Error rendering app:", error);
  }
};

renderApp();
